// FS Next entrypoint
//
// Many of the SCSS files in fs-next don't actually emit any CSS by themselves —
// they behave like libraries. This file contains mixins that call the libraries
// to emit actual CSS.

@use '@angular/material' as mat;

@use './material/material.theme' as themes;
@use './theming/animation' as animation;
@use './theming/color' as color;
@use './theming/color/color.util' as color-util;
@use './theming/elevation' as elevation;
@use './theming/palette' as palettes;
@use './theming/shape' as shape;
@use './theming/typography' as typography;
@use './theming/themes.output' as themes-output;

@use './builtins' as builtins;

@use './beta-badge/beta-badge.component-theme' as beta-badge;
@use './big-tabs/big-tabs.component-theme' as big-tabs;
@use './databox/databox.component-theme' as databox;
@use './pill-tabs/pill-tabs.component-theme' as pill-tabs;
@use './squishy-button/squishy-button.component-theme' as squishy-button;
@use './stepper/stepper.component-theme' as stepper;

@mixin core {
  // Provide styling for standard HTML elements such as h1, h2, p, etc. These
  // will only be used if the element or an ancestor has the .fs-next class
  // applied.
  @include typography.typography-hierarchy(typography.$fs-fonts, $cls: '.fs-next', $prefix: 'fsn-');
  @include builtins.all-components($cls: '.fs-next', $prefix: 'fsn-');
}

@mixin output-global-tokens() {
  @include typography.output-typeface-tokens-as-css-vars();
  @include animation.output-animation-tokens-as-css-vars();
  @include palettes.output-all-palettes-as-css-vars();
  @include shape.output-shape-tokens-as-css-vars();
}

@mixin output-theme-css-vars($mode: 'light') {
  @if ($mode == 'light') {
    $theme: (
      color: color.$base-light-color-theme,
    );

    // Typography included here because light/dark may have different font weights, letter spacing, etc.
    @include typography.output-typography-tokens-as-css-vars($theme);
    @include themes-output.output-theme-css-vars($theme);

    .theme-biodiversity-blue {
      $theme: (
        color: color-util.aliased-tokens-from-tonal-palette(palettes.$biodiversity-blue),
      );
      @include themes-output.output-theme-css-vars($theme);
    }
    .theme-green-tea {
      $theme: (
        color: color-util.aliased-tokens-from-tonal-palette(palettes.$emissions-green),
      );
      @include themes-output.output-theme-css-vars($theme);
    }
    .theme-orchid {
      $theme: (
        color: color-util.aliased-tokens-from-tonal-palette(palettes.$forced-labor-orchid),
      );
      @include themes-output.output-theme-css-vars($theme);
    }
    .theme-pink {
      $theme: (
        color: color-util.aliased-tokens-from-tonal-palette(palettes.$summary-pink),
      );
      @include themes-output.output-theme-css-vars($theme);
    }
    .theme-gray {
      $theme: (
        color: color-util.aliased-tokens-from-tonal-palette(palettes.$foundation-gray),
      );
      @include themes-output.output-theme-css-vars($theme);
    }
    .theme-guidance-blue {
      $theme: (
        color: color-util.aliased-tokens-from-tonal-palette(palettes.$guidance-blue),
      );
      @include themes-output.output-theme-css-vars($theme);
    }
  } @else {
    $theme: (
      color: color.$base-dark-color-theme,
    );

    // Typography included here because light/dark may have different font weights, letter spacing, etc.
    @include typography.output-typography-tokens-as-css-vars($theme);
    // TODO: Complete the dark theme palette and output the whole thing just like the light theme. As it's currently written, it's a second-class citizen.
    @include themes-output.output-theme-css-vars($theme);

    // TODO: dark mode variants of special themes like biodiversity-blue
  }
}

@mixin remap-theme-css-vars($from-mode) {
}

// TODO: Probably: Replace this with direct references to the tokens (CSS vars) outputted above. No need for -theme.scss files.
@mixin all-component-themes($theme) {
  // Elements
  @include builtins.a-theme($theme);
  // Components
  @include beta-badge.theme($theme);
  @include big-tabs.theme($theme);
  @include databox.theme($theme);
  @include pill-tabs.theme($theme);
  @include squishy-button.theme($theme);
  @include stepper.theme($theme);
}

// These are special cases that can't work with the "main" theme, because currently that theme is too limited, having only "primary" and "accent" palettes, neither of which are suitable.
@mixin special-case-component-themes {
  @include builtins.aside-theme(themes.$gray);
}

// Scoped themes for special parts of the app.
@mixin all-color($theme) {
  @include beta-badge.color($theme);
  @include pill-tabs.color($theme);
  @include squishy-button.color($theme);
  @include stepper.color($theme);
}
