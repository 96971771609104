// Copyright (C) 2021 Fair Supply Analytics Pty Ltd - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.

$flags-images-path: '/assets/images' !default;
.f32 {
  .flag {
    background: url(#{$flags-images-path}/flags32.png) no-repeat;
  }

  ._African_Union {
    background-position: 0 -32px;
  }
  ._Arab_League {
    background-position: 0 -64px;
  }
  ._ASEAN {
    background-position: 0 -96px;
  }
  ._CARICOM {
    background-position: 0 -128px;
  }
  ._CIS {
    background-position: 0 -160px;
  }
  ._Commonwealth {
    background-position: 0 -192px;
  }
  ._England {
    background-position: 0 -224px;
  }
  ._European_Union {
    background-position: 0 -256px;
  }
  ._Islamic_Conference {
    background-position: 0 -288px;
  }
  ._Kosovo {
    background-position: 0 -320px;
  }
  ._NATO {
    background-position: 0 -352px;
  }
  ._Northern_Cyprus {
    background-position: 0 -384px;
  }
  ._Northern_Ireland {
    background-position: 0 -416px;
  }
  ._Olimpic_Movement {
    background-position: 0 -448px;
  }
  ._OPEC {
    background-position: 0 -480px;
  }
  ._Red_Cross {
    background-position: 0 -512px;
  }
  ._Scotland {
    background-position: 0 -544px;
  }
  ._Somaliland {
    background-position: 0 -576px;
  }
  ._Tibet {
    background-position: 0 -608px;
  }
  ._United_Nations {
    background-position: 0 -640px;
  }
  ._Wales {
    background-position: 0 -672px;
  }

  .eu {
    background-position: 0 -256px;
  }

  .and {
    background-position: 0 -704px;
  }
  .are {
    background-position: 0 -736px;
  }
  .afg {
    background-position: 0 -768px;
  }
  .atg {
    background-position: 0 -800px;
  }
  .aia {
    background-position: 0 -832px;
  }
  .alb {
    background-position: 0 -864px;
  }
  .arm {
    background-position: 0 -896px;
  }
  .ago {
    background-position: 0 -928px;
  }
  .ata {
    background-position: 0 -960px;
  }
  .arg {
    background-position: 0 -992px;
  }
  .asm {
    background-position: 0 -1024px;
  }
  .aut {
    background-position: 0 -1056px;
  }
  .aus {
    background-position: 0 -1088px;
  }
  .abw {
    background-position: 0 -1120px;
  }
  .ala {
    background-position: 0 -1152px;
  }
  .aze {
    background-position: 0 -1184px;
  }
  .bih {
    background-position: 0 -1216px;
  }
  .brb {
    background-position: 0 -1248px;
  }
  .bgd {
    background-position: 0 -1280px;
  }
  .bel {
    background-position: 0 -1312px;
  }
  .bfa {
    background-position: 0 -1344px;
  }
  .bgr {
    background-position: 0 -1376px;
  }
  .bhr {
    background-position: 0 -1408px;
  }
  .bdi {
    background-position: 0 -1440px;
  }
  .ben {
    background-position: 0 -1472px;
  }
  .bmu {
    background-position: 0 -1504px;
  }
  .brn {
    background-position: 0 -1536px;
  }
  .bol {
    background-position: 0 -1568px;
  }
  .bra {
    background-position: 0 -1600px;
  }
  .bhs {
    background-position: 0 -1632px;
  }
  .btn {
    background-position: 0 -1664px;
  }
  .bwa {
    background-position: 0 -1696px;
  }
  .blr {
    background-position: 0 -1728px;
  }
  .blz {
    background-position: 0 -1760px;
  }
  .can {
    background-position: 0 -1792px;
  }
  .cod {
    background-position: 0 -1824px;
  }
  .rca {
    background-position: 0 -1856px;
  }
  .cog {
    background-position: 0 -1888px;
  }
  .che {
    background-position: 0 -1920px;
  }
  .civ {
    background-position: 0 -1952px;
  }
  .cok {
    background-position: 0 -1984px;
  }
  .chl {
    background-position: 0 -2016px;
  }
  .cmr {
    background-position: 0 -2048px;
  }
  .chn {
    background-position: 0 -2080px;
  }
  .col {
    background-position: 0 -2112px;
  }
  .cri {
    background-position: 0 -2144px;
  }
  .cub {
    background-position: 0 -2176px;
  }
  .cpv {
    background-position: 0 -2208px;
  }
  .cyp {
    background-position: 0 -2240px;
  }
  .cze {
    background-position: 0 -2272px;
  }
  .deu {
    background-position: 0 -2304px;
  }
  .dji {
    background-position: 0 -2336px;
  }
  .dnk {
    background-position: 0 -2368px;
  }
  .dma {
    background-position: 0 -2400px;
  }
  .dom {
    background-position: 0 -2432px;
  }
  .dza {
    background-position: 0 -2464px;
  }
  .ecu {
    background-position: 0 -2496px;
  }
  .est {
    background-position: 0 -2528px;
  }
  .egy {
    background-position: 0 -2560px;
  }
  .esh {
    background-position: 0 -2592px;
  }
  .eri {
    background-position: 0 -2624px;
  }
  .esp {
    background-position: 0 -2656px;
  }
  .eth {
    background-position: 0 -2688px;
  }
  .fin {
    background-position: 0 -2720px;
  }
  .fji {
    background-position: 0 -2752px;
  }
  .fsm {
    background-position: 0 -2784px;
  }
  .fro {
    background-position: 0 -2816px;
  }
  .fra {
    background-position: 0 -2848px;
  }
  .blm {
    background-position: 0 -2848px;
  }
  .cpt {
    background-position: 0 -2848px;
  }
  .maf {
    background-position: 0 -2848px;
  }
  .myt {
    background-position: 0 -2848px;
  }
  .gab {
    background-position: 0 -2880px;
  }
  .gbr {
    background-position: 0 -2912px;
  }
  .shn {
    background-position: 0 -2912px;
  }
  .grd {
    background-position: 0 -2944px;
  }
  .geo {
    background-position: 0 -2976px;
  }
  .ggy {
    background-position: 0 -3008px;
  }
  .gha {
    background-position: 0 -3040px;
  }
  .gib {
    background-position: 0 -3072px;
  }
  .grl {
    background-position: 0 -3104px;
  }
  .gmb {
    background-position: 0 -3136px;
  }
  .gin {
    background-position: 0 -3168px;
  }
  .glp {
    background-position: 0 -3200px;
  }
  .gnq {
    background-position: 0 -3232px;
  }
  .grc {
    background-position: 0 -3264px;
  }
  .gtm {
    background-position: 0 -3296px;
  }
  .gum {
    background-position: 0 -3328px;
  }
  .gnb {
    background-position: 0 -3360px;
  }
  .guy {
    background-position: 0 -3392px;
  }
  .hkg {
    background-position: 0 -3424px;
  }
  .hnd {
    background-position: 0 -3456px;
  }
  .hrv {
    background-position: 0 -3488px;
  }
  .hti {
    background-position: 0 -3520px;
  }
  .hun {
    background-position: 0 -3552px;
  }
  // .idn (Indonesia), .mco (Monaco) flags are similar; as a result use the same sprite.
  .idn {
    background-position: 0 -3584px;
  }
  .mco {
    background-position: 0 -3584px;
  }
  .irl {
    background-position: 0 -3616px;
  }
  .isr {
    background-position: 0 -3648px;
  }
  .imn {
    background-position: 0 -3680px;
  }
  .ind {
    background-position: 0 -3712px;
  }
  .irq {
    background-position: 0 -3744px;
  }
  .irn {
    background-position: 0 -3776px;
  }
  .isl {
    background-position: 0 -3808px;
  }
  .ita {
    background-position: 0 -3840px;
  }
  .jey {
    background-position: 0 -3872px;
  }
  .jam {
    background-position: 0 -3904px;
  }
  .jor {
    background-position: 0 -3936px;
  }
  .jpn {
    background-position: 0 -3968px;
  }
  .ken {
    background-position: 0 -4000px;
  }
  .kgz {
    background-position: 0 -4032px;
  }
  .khm {
    background-position: 0 -4064px;
  }
  .kir {
    background-position: 0 -4096px;
  }
  .com {
    background-position: 0 -4128px;
  }
  .kna {
    background-position: 0 -4160px;
  }
  .prk {
    background-position: 0 -4192px;
  }
  .kor {
    background-position: 0 -4224px;
  }
  .kwt {
    background-position: 0 -4256px;
  }
  .cym {
    background-position: 0 -4288px;
  }
  .kaz {
    background-position: 0 -4320px;
  }
  .lao {
    background-position: 0 -4352px;
  }
  .lbn {
    background-position: 0 -4384px;
  }
  .lca {
    background-position: 0 -4416px;
  }
  .lie {
    background-position: 0 -4448px;
  }
  .lka {
    background-position: 0 -4480px;
  }
  .lbr {
    background-position: 0 -4512px;
  }
  .lso {
    background-position: 0 -4544px;
  }
  .ltu {
    background-position: 0 -4576px;
  }
  .lux {
    background-position: 0 -4608px;
  }
  .lva {
    background-position: 0 -4640px;
  }
  .lby {
    background-position: 0 -4672px;
  }
  .mar {
    background-position: 0 -4704px;
  }
  .mda {
    background-position: 0 -4736px;
  }
  .mne {
    background-position: 0 -4768px;
  }
  .mdg {
    background-position: 0 -4800px;
  }
  .mhl {
    background-position: 0 -4832px;
  }
  .mkd {
    background-position: 0 -4864px;
  }
  .mli {
    background-position: 0 -4896px;
  }
  .mmr {
    background-position: 0 -4928px;
  }
  .mng {
    background-position: 0 -4960px;
  }
  .mac {
    background-position: 0 -4992px;
  }
  .mtq {
    background-position: 0 -5024px;
  }
  .mrt {
    background-position: 0 -5056px;
  }
  .msr {
    background-position: 0 -5088px;
  }
  .mlt {
    background-position: 0 -5120px;
  }
  .mus {
    background-position: 0 -5152px;
  }
  .mdv {
    background-position: 0 -5184px;
  }
  .mwi {
    background-position: 0 -5216px;
  }
  .mex {
    background-position: 0 -5248px;
  }
  .mys {
    background-position: 0 -5280px;
  }
  .moz {
    background-position: 0 -5312px;
  }
  .nam {
    background-position: 0 -5344px;
  }
  .ncl {
    background-position: 0 -5376px;
  }
  .ner {
    background-position: 0 -5408px;
  }
  .nga {
    background-position: 0 -5440px;
  }
  .nic {
    background-position: 0 -5472px;
  }
  .nld {
    background-position: 0 -5504px;
  }
  .bes {
    background-position: 0 -5504px;
  }
  .nor {
    background-position: 0 -5536px;
  }
  .bvt {
    background-position: 0 -5536px;
  }
  .atn {
    background-position: 0 -5536px;
  }
  .sjm {
    background-position: 0 -5536px;
  }
  .npl {
    background-position: 0 -5568px;
  }
  .nru {
    background-position: 0 -5600px;
  }
  .nzl {
    background-position: 0 -5632px;
  }
  .omn {
    background-position: 0 -5664px;
  }
  .pan {
    background-position: 0 -5696px;
  }
  .per {
    background-position: 0 -5728px;
  }
  .pyf {
    background-position: 0 -5760px;
  }
  .png {
    background-position: 0 -5792px;
  }
  .phl {
    background-position: 0 -5824px;
  }
  .pak {
    background-position: 0 -5856px;
  }
  .pol {
    background-position: 0 -5888px;
  }
  .pri {
    background-position: 0 -5920px;
  }
  .pse {
    background-position: 0 -5952px;
  }
  .prt {
    background-position: 0 -5984px;
  }
  .plw {
    background-position: 0 -6016px;
  }
  .pry {
    background-position: 0 -6048px;
  }
  .qat {
    background-position: 0 -6080px;
  }
  .reu {
    background-position: 0 -6112px;
  }
  .rou {
    background-position: 0 -6144px;
  }
  .srb {
    background-position: 0 -6176px;
  }
  .rus {
    background-position: 0 -6208px;
  }
  .rwa {
    background-position: 0 -6240px;
  }
  .sau {
    background-position: 0 -6272px;
  }
  .slb {
    background-position: 0 -6304px;
  }
  .syc {
    background-position: 0 -6336px;
  }
  .sdn {
    background-position: 0 -6368px;
  }
  .swe {
    background-position: 0 -6400px;
  }
  .sgp {
    background-position: 0 -6432px;
  }
  .svn {
    background-position: 0 -6464px;
  }
  .svk {
    background-position: 0 -6496px;
  }
  .sle {
    background-position: 0 -6528px;
  }
  .smr {
    background-position: 0 -6560px;
  }
  .sen {
    background-position: 0 -6592px;
  }
  .som {
    background-position: 0 -6624px;
  }
  .sur {
    background-position: 0 -6656px;
  }
  .stp {
    background-position: 0 -6688px;
  }
  .slv {
    background-position: 0 -6720px;
  }
  .syr {
    background-position: 0 -6752px;
  }
  .swz {
    background-position: 0 -6784px;
  }
  .tca {
    background-position: 0 -6816px;
  }
  .tcd {
    background-position: 0 -6848px;
  }
  .tgo {
    background-position: 0 -6880px;
  }
  .tha {
    background-position: 0 -6912px;
  }
  .tjk {
    background-position: 0 -6944px;
  }
  .tls {
    background-position: 0 -6976px;
  }
  .tkm {
    background-position: 0 -7008px;
  }
  .tun {
    background-position: 0 -7040px;
  }
  .ton {
    background-position: 0 -7072px;
  }
  .tur {
    background-position: 0 -7104px;
  }
  .tto {
    background-position: 0 -7136px;
  }
  .tuv {
    background-position: 0 -7168px;
  }
  .twn {
    background-position: 0 -7200px;
  }
  .tza {
    background-position: 0 -7232px;
  }
  .ukr {
    background-position: 0 -7264px;
  }
  .uga {
    background-position: 0 -7296px;
  }
  .usa {
    background-position: 0 -7328px;
  }
  .ury {
    background-position: 0 -7360px;
  }
  .uzb {
    background-position: 0 -7392px;
  }
  .vat {
    background-position: 0 -7424px;
  }
  .vct {
    background-position: 0 -7456px;
  }
  .ven {
    background-position: 0 -7488px;
  }
  .vgb {
    background-position: 0 -7520px;
  }
  .vir {
    background-position: 0 -7552px;
  }
  .vnm {
    background-position: 0 -7584px;
  }
  .vut {
    background-position: 0 -7616px;
  }
  .wsm {
    background-position: 0 -7648px;
  }
  .yem {
    background-position: 0 -7680px;
  }
  .zaf {
    background-position: 0 -7712px;
  }
  .zmb {
    background-position: 0 -7744px;
  }
  .zwe {
    background-position: 0 -7776px;
  }
  .sxm {
    background-position: 0 -7808px;
  }
  .cuw {
    background-position: 0 -7840px;
  }
  .ssd {
    background-position: 0 -7872px;
  }
  .niu {
    background-position: 0 -7904px;
  }
} // .f32
